import { render } from "./pzhenSorder.vue?vue&type=template&id=f51917d8&scoped=true"
import script from "./pzhenSorder.vue?vue&type=script&lang=js"
export * from "./pzhenSorder.vue?vue&type=script&lang=js"

import "./pzhenSorder.vue?vue&type=style&index=0&id=f51917d8&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-f51917d8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "f51917d8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f51917d8', script)) {
    api.reload('f51917d8', script)
  }
  
  module.hot.accept("./pzhenSorder.vue?vue&type=template&id=f51917d8&scoped=true", () => {
    api.rerender('f51917d8', render)
  })

}

script.__file = "src/views/home/sorder/pzhenSorder.vue"

export default script